const backendUrl = process.env.REACT_APP_BACKEND_URL;
const storageUrl = "https://storage.luvbomb.meme";

// ---- GETTER ----

export function getTokenImageLink(chainName, tokenContract, fileType) {
	const baseLink = storageUrl + "/images/";
	const fileName = chainName + "-" + tokenContract + "." + fileType;

	return baseLink + fileName;
}

// ---- POST - VERIFY ----

export async function verifyContract(contract, data) {
	let urlPart = "/verify";

	let json = {
		contract: contract,
		data: data
	};

	return request(urlPart, json);
}

// ---- POST - WHITELIST ----

export async function whitelistContract(contract, applicationText) {
	let urlPart = "/whitelist";

	let json = {
		contract: contract,
		text: applicationText
	};

	return request(urlPart, json);
}

// ---- POST - STORAGE - IMAGE ----

export async function createPresignedUrl(wallet, chainName, tokenContract, fileType) {
	let urlPart = "/storage";

	let json = {
		wallet: wallet,
		chainName: chainName,
		tokenContract: tokenContract,
		fileType: fileType
	};

	return request(urlPart, json);
}

// ---- POST - STORAGE - CHAT ----

export async function postChatMessage(senderAddress, message) {
	let urlPart = "/chat";

	let json = {
		sender: senderAddress,
		message: message
	};

	return request(urlPart, json);
}

// ---- REQUESTS ----

async function request(urlPart, jsonBody) {
	let url = backendUrl + urlPart;
	let options = {
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json"
		},
		mode: "cors"
	};

	if (jsonBody) {
		options.body = JSON.stringify(jsonBody);
		options.method = "POST";
	} else {
		options.method = "GET";
	}

	return fetch(url, options).then(response => {
		if (response.ok === true || response.status === 400) {
			return response.json();
		} else {
			throw new Error("Request is not OK");
		}
	});
}
