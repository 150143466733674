import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
import InternalLink from "../../common/links/InternalLink.js";
import Background from "../../common/others/Background.js";
import Logo from "../../common/others/Logo.js";
import ChainDropdown from "./ChainDropdown.js";
import ConnectButton from "./ConnectButton.js";
import MenuButton from "./MenuButton.js";
import ProviderModal from "./ProviderModal.js";
import SearchBar from "./SearchBar.js";
import { ProviderModalContext } from "../../../components-helper/contexts/ProviderModalProvider.js";
import { WindowWidthContext } from "../../../components-helper/contexts/WindowWidthProvider.js";
import { conc, cond, getCssVariablePixel } from "../../../lib/wrapper/html.js";

export default function Header({isDummy}) {
	const mobileBreakpoint = getCssVariablePixel("--mobile-breakpoint-big");

	const location = useLocation();

	const { isModalOpen, setIsModalOpen } = useContext(ProviderModalContext);
	const windowWidth = useContext(WindowWidthContext);

	const [isChecked, setIsChecked] = useState(false);

	// ---- HOOKS ----

	useEffect(() => {
		resetModal();
	}, [location]);

	useEffect(() => {
		if (isChecked === true && windowWidth > mobileBreakpoint) {
			setIsChecked(false);
		}
	}, [windowWidth]);

	useEffect(() => {
		if (isChecked === false) {
			setIsModalOpen(false);
		}
	}, [isChecked]);

	useEffect(() => {
		setIsChecked(isModalOpen);
	}, [isModalOpen]);

	// ---- FUNCTIONS ----

	function resetModal() {
		setIsChecked(false);
		setIsModalOpen(false);
	}

	// ---- FUNCTIONS (CLICK HANDLERS) ----

	function handleConnectWalletButton() {
		setIsModalOpen(true);
	}

	return (
		windowWidth > mobileBreakpoint
		?
			<header className={conc(styles.header, styles.header_desktop)}>
				<Background isActive={isModalOpen} onClick={() => setIsModalOpen(false)}/>

				<div className={styles.header_desktop_inner_container}>
					<div className={styles.search_bar_container}>
						<SearchBar/>
					</div>

					<div className={styles.function_container}>
						<ConnectButton onClick={handleConnectWalletButton}/>
						<ChainDropdown/>
					</div>
				</div>

				{
					isModalOpen &&
					<ProviderModal closeModal={() => resetModal()}/>
				}
			</header>
		:
			<header className={conc(styles.header, styles.header_mobile)}>
				<Background isActive={isChecked || isModalOpen} onClick={() => resetModal()}/>

				<div className={styles.header_mobile_inner_container}>
					<Logo/>
					
					<div className={styles.search_bar_container}>
						<SearchBar/>
					</div>
				</div>

				<MenuButton getter={isChecked} setter={setIsChecked}/>

				{
					isModalOpen && isChecked
					?
						<ProviderModal closeModal={() => resetModal()}/>
					:
						<nav className={conc(styles.nav_mobile, cond(isChecked, styles.nav_mobile_visible))}>
							<InternalLink link={"/history"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Launches</InternalLink>
							<InternalLink link={"/create"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Create</InternalLink>
							<InternalLink link={"/manage"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Manage</InternalLink>
							
							<hr/>

							<InternalLink link={"/staking"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Staking</InternalLink>
							<InternalLink link={"/dao"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>DAO</InternalLink>
							<InternalLink link={"/whitelist"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Whitelist</InternalLink>
							<InternalLink link={"/raise"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Raise</InternalLink>

							<hr/>

							<InternalLink link={"/tokenomics"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Tokenomics</InternalLink>
							<InternalLink link={"/about"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>About</InternalLink>

							<hr/>

							<div className={styles.function_container}>
								<ConnectButton onClick={handleConnectWalletButton}/>
								<ChainDropdown/>
							</div>
						</nav>
				}
			</header>
	);
}