import { Outlet } from "react-router-dom";
import styles from "./Main.module.scss";
import Header from "./header/Header.js";
import Sidebar from "./Sidebar.js";

export default function Main() {
	return (
		<div>
			<Header/>
			<Sidebar/>
			
			<main className={styles.main}>
				<Outlet/>
			</main>
		</div>
	);
}