import { web3s } from "./web3Base.js";
import { getChatMessages } from "./web3Token.js";
import { getTokenImageLink } from "../api/backend.js";
import { getEvent } from "../api/moralis.js";
import { toDateString } from "../helper.js";

import { getChains } from "../../data/chains.js";
import { contracts } from "../../data/contracts.js";

import standardTokenAbi from "../../data/abis/standardToken.json";
import feeTokenAbi from "../../data/abis/feeToken.json";

import standardFactoryAbi from "../../data/abis/standardFactory.json";
import feeFactoryAbi from "../../data/abis/feeFactory.json";
import clogFactoryAbi from "../../data/abis/clogFactory.json";

export const stakeSymbol = "LUV";
export const unstakeSymbol = "freeLUV";
export const currencySymbol = "BNB";
export const depositSymbol = "USDC";

export const zeroAddress = "0x000000000000000000000000000000000000dEaD";

export const tokenLimits = {
	name: 30,
	symbol: 6,
	desription: 200,
	url: 200
};

export const fileTypes = ["image/jpeg", "image/png"];
export const maxFileSize = 1 * 1024 * 1024;

// ---- GETTER - ALL TOKENS ----

export async function getCreatedTokens() {
	let chains = getChains();
	let tokens = [];

	for (const chain of chains) {
		let standardEvent = "NewERC20TokenCreated";
		let feeEvent = "NewFEETokenCreated";
		let clogEvent = "NewCLOGTokenCreated";

		let standardAbi = standardFactoryAbi.find(element => element.type === "event" && element.name === standardEvent);
		let feeAbi = feeFactoryAbi.find(element => element.type === "event" && element.name === feeEvent);
		let clogAbi = clogFactoryAbi.find(element => element.type === "event" && element.name === clogEvent);

		let standardTokens = await getEvent(chain, contracts[chain.nameId].standardFactory, standardEvent, standardAbi);
		let feeTokens = await getEvent(chain, contracts[chain.nameId].feeFactory, feeEvent, feeAbi);
		let clogTokens = await getEvent(chain, contracts[chain.nameId].clogFactory, clogEvent, clogAbi);

		let allTokens = [
			...standardTokens.map(token => ({ ...token, type: "standard" })),
			...feeTokens.map(token => ({ ...token, type: "fee" })),
			...clogTokens.map(token => ({ ...token, type: "clog" }))
		];

		let newTokenPromises = allTokens.map(async token => {
			let contract = token.data.newToken;
			let type = token.type;

			let creationDate = new Date(token.block_timestamp);
			let creationDateString = toDateString(creationDate).split(", ")[0];

			return Promise.all([
				getBasicData(chain, contract),
				getSocialData(chain, contract),
				getTypeData(chain, contract, type),
				getFinanceData(chain, contract),
				getChatMessages(token)
			]).then(results => {
				let basicData = results[0];
				let socialData = results[1];
				let typeData = results[2];
				let financeData = results[3];
				let chatCount = results[4];

				let imageLink = getImageLink(chain, contract, socialData.logo);

				return {
					contract: contract,
					creationDate: creationDateString,
					type: type,
					imageLink: imageLink,
					chain: chain,
					basicData: basicData,
					socialData: socialData,
					typeData: typeData,
					financeData: financeData,
					chatCount: chatCount.length
				};
			});
		});

		let newTokens = await Promise.all(newTokenPromises);

		tokens = [...tokens, ...newTokens];
	}

	return tokens;
}

async function getBasicData(chain, contract) {
	let tokenContract = new web3s[chain.nameId].Contract(standardTokenAbi, contract);

	return Promise.all([
		tokenContract.methods.name().call(),
		tokenContract.methods.symbol().call()
	]).then(results => {
		return {
			name: results[0].toUpperCase(),
			symbol: results[1].toUpperCase()
		}
	});
}

async function getSocialData(chain, contract) {
	let tokenContract = new web3s[chain.nameId].Contract(standardTokenAbi, contract);

	return Promise.all([
		tokenContract.methods.mediaAccount().call(),
		tokenContract.methods.metadata().call(),
	]).then(results => {
		let mediaAccount = results[0];
		let metadata = results[1];

		return {
			mediaAccount: mediaAccount,
			uri: metadata.uri_,
			description: metadata.description_,
			logo: metadata.logo_,
			website: metadata.website_,
			telegram: metadata.telegram_,
			twitter: metadata.twitter_,
			discord: metadata.discord_,
		}
	});
}

async function getTypeData(chain, contract, type) {
	if (type !== "standard") {
		let tokenContract = new web3s[chain.nameId].Contract(feeTokenAbi, contract);

		return Promise.all([
			tokenContract.methods.feeAccount().call()
		]).then(results => {
			let dataName = type + "Account";
	
			return {
				[dataName]: results[0],
			};
		});
	} else {
		return undefined;
	}
}

async function getFinanceData(chain, contract) {
	return Promise.all([
		getMarketCap(chain, contract),
		getVolume(chain, contract)
	]).then(results => {
		return {
			marketCap: results[0],
			volume: results[1]
		};
	});
}

// ---- GETTER - SPECIFIC TOKEN ----

export function getImageLink(chain, tokenContract, logo) {
	let link = undefined;

	let acceptedFileTypes = fileTypes.map(type => type.split("/")[1]);

	if (acceptedFileTypes.includes(logo)) {
		link = getTokenImageLink(chain.nameId, tokenContract, logo);
	} else {
		link = "/images/decorations/default.png";
	}

	return link;
}

export async function getMarketCap(chain, contract) {
	return 45677.88;
}

export async function getVolume(chain, contract) {
	return 1000;

	/* token0 = pool_info['token0']
	token1 = pool_info['token1']

	amount0_in = decoded_log['args']['amount0In']
	amount1_in = decoded_log['args']['amount1In']
	amount0_out = decoded_log['args']['amount0Out']
	amount1_out = decoded_log['args']['amount1Out']

	if token0 in self.baseTokens and amount0_in > 0 and amount1_out > 0:
		# Base token (token0) is being sold to buy token1
		base_token = token0
		bought_token = token1
		base_amount = amount0_in
		bought_amount = amount1_out

	elif token1 in self.baseTokens and amount1_in > 0 and amount0_out > 0:
		# Base token (token1) is being sold to buy token0
		base_token = token1
		bought_token = token0
		base_amount = amount1_in
		bought_amount = amount0_out */
}