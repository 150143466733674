import { Decimal } from "decimal.js";

import { web3s, web3Provider, currentAccount, getSendMessage, toWei, fromWei, approve } from "./web3Base.js";
import { currencySymbol, stakeSymbol } from "./web3BaseAdd.js";

import { contracts } from "../../data/contracts.js";
import { getToken } from "../../data/tokens.js";

import basicTokenAbi from "../../data/abis/basicToken.json";
import clogTokenAbi from "../../data/abis/clogToken.json";
import feeTokenAbi from "../../data/abis/feeToken.json";
import routerAbi from "../../data/abis/router.json";

export const mainTokenFee = 0.002;

// ---- GETTER ----

export async function getBottomValue(token, isBuy, inputAmount, fee) {
	let routerContract = new web3s[token.chain.nameId].Contract(routerAbi, contracts[token.chain.nameId].router);

	let mainToken = getToken(token.chain.nameId, stakeSymbol);
	let currencyToken = getToken(token.chain.nameId, currencySymbol);

	let inputAmountWei = toWei(inputAmount);

	let path1 = isBuy ? [currencyToken.wrappedContract, mainToken.contract] : [token.contract, mainToken.contract];
	let path2 = isBuy ? [mainToken.contract, token.contract] : [mainToken.contract, currencyToken.wrappedContract];

	let one = new Decimal("1");
	let mainTokenFeeDecimal = new Decimal(mainTokenFee);
	let feeDecimal = new Decimal(fee);

	let amount1Wei = await routerContract.methods.getAmountsOut(inputAmountWei, path1).call();
	let amount1 = fromWei(amount1Wei[1]);

	let amount1Decimal = new Decimal(amount1);
	let feeValue1 = one.minus(mainTokenFeeDecimal);
	let feeAmount1 = amount1Decimal.times(feeValue1);
	let feeAmountWei1 = toWei(feeAmount1);

	let amount2Wei = await routerContract.methods.getAmountsOut(feeAmountWei1, path2).call();
	let amount2 = fromWei(amount2Wei[1]);

	let amount2Decimal = new Decimal(amount2);
	let feeValue2 = one.minus(feeDecimal);
	let feeAmount2 = amount2Decimal.times(feeValue2);

	return feeAmount2;
}

export async function getTokenFee(token) {
	let fee = undefined;

	switch (token.type) {
		case "standard":
			fee = new Decimal("0");

			break;
		case "fee":
			let feeTokenContract = new web3s[token.chain.nameId].Contract(feeTokenAbi, token.contract);
			let feeFeeNominator = await feeTokenContract.methods.feeNominator().call();

			let feeThousand = new Decimal("1000");
			let feeFeeNominatorDecimal = new Decimal(feeFeeNominator);
			
			fee = feeFeeNominatorDecimal.dividedBy(feeThousand);

			break;
		case "clog":
			let clogtokenContract = new web3s[token.chain.nameId].Contract(clogTokenAbi, token.contract);

			let struct = await clogtokenContract.methods.contractStruct().call();
			let txnCount = await clogtokenContract.methods.txnCount().call();

			let clogFeeNominator = (txnCount < struct.txnCountLimit) ? struct.startFeeNominator : struct.endFeeNominator;

			let clogThousand = new Decimal("1000");
			let clogFeeNominatorDecimal = new Decimal(clogFeeNominator);
			
			fee = clogFeeNominatorDecimal.dividedBy(clogThousand);

			break;
	}

	return fee;
}

export async function getTokenSlippage(token) {
	let slippage = new Decimal("1");
	
	if (token.type !== "standard") {
		let tokenFee = await getTokenFee(token);

		slippage = slippage.add(tokenFee);
	}

	return slippage;
}

function getDeadline() {
	return Math.floor(Date.now() / 1000) + 300;
}

// ---- INTERACTIONS ----

export async function swapBuy(token, inAmount, outAmount) {
	let routerContract = new web3Provider.Contract(routerAbi, contracts[token.chain.nameId].router);

	let weiAmountIn = toWei(inAmount);
	let weiAmountOut = toWei(outAmount);

	let mainToken = getToken(token.chain.nameId, stakeSymbol);
	let currencyToken = getToken(token.chain.nameId, currencySymbol);
	let path = [currencyToken.wrappedContract, mainToken.contract, token.contract];

	let deadline = getDeadline();

	let extraMessage = {
		value: weiAmountIn.toString()
	};

	return routerContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
		weiAmountOut,
		path,
		currentAccount,
		deadline
	).send(getSendMessage(extraMessage));
}

export async function swapSell(token, inAmount, outAmount) {
	let routerContract = new web3Provider.Contract(routerAbi, contracts[token.chain.nameId].router);
	let tokenContract = new web3Provider.Contract(basicTokenAbi, token.contract);

	let weiAmountIn = toWei(inAmount);
	let weiAmountOut = toWei(outAmount);

	let mainToken = getToken(token.chain.nameId, stakeSymbol);
	let currencyToken = getToken(token.chain.nameId, currencySymbol);
	let path = [token.contract, mainToken.contract, currencyToken.wrappedContract];

	let deadline = getDeadline();

	return approve(tokenContract, contracts[token.chain.nameId].router, weiAmountIn).then(() => {
		return routerContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
			weiAmountIn,
			weiAmountOut,
			path,
			currentAccount,
			deadline
		).send(getSendMessage());
	});
}