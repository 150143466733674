import { isTestnetPath } from "../lib/helper.js";

const chains = [
	{
		id: 97,
		hex: "0x61",
		url: "https://data-seed-prebsc-1-s1.binance.org:8545",
		name: "Binance Smart Chain Testnet",
		nameId: "BSCT",
		currency: {
			name: "Testnet Binance Coin",
			symbol: "tBNB",
			decimals: 18,
		},
		blockExplorer: "https://testnet.bscscan.com",
		lpSymbol: "CAKE-LP",
		lpUrl: "https://pancakeswap.finance?chain=bscTestnet",
		wrappedContract: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
		moralisId: "bsc testnet"
	}
];

export function getChains() {
	let filteredChains = chains;

	if (!isTestnetPath()) {
		filteredChains = filteredChains.filter(chain => chain.id !== 97);
	}

	return filteredChains;
}

export function getChain(nameId) {
	return chains.find(chain => chain.nameId === nameId);
}

export function getChainById(id) {
	return chains.find(chain => chain.id === id);
}

export function getWalletConnectChainIds() {
	return [56];
}