import { useLocation } from "react-router-dom";
import styles from "./LoadingSpinnerPage.module.scss";
import Main from "../../structure/Main.js";

import { projectName } from "../../../data/project.js";

export default function LoadingSpinnerPage() {
	const location = useLocation();
    const isLandingPage = location.pathname === "/";
	
	// <img className={styles.image} src={"/images/decorations/hearted_circle.png"} alt={projectName + " - Loading Heart"}/>

	return (
		isLandingPage
		?
			<div/>
		:
			<Main/>
	);
}