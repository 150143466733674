import { projectName } from "./project.js";

const baseImagePath = "/images/socials/";

const socials = [
	{
		link: undefined,
		imagePath: baseImagePath + "zealy.svg",
		altText: projectName + "Zealy",
		color: "#000000"
	},
	{
		link: undefined,
		imagePath: baseImagePath + "discord.svg",
		altText: projectName + "Discord",
		color: "#5865f2"
	},
	{
		link: "https://t.me/luvtomeme",
		imagePath: baseImagePath + "telegram.svg",
		altText: projectName + "Telegram",
		color: "#25a3e2"
	},
	{
		link: "https://x.com/luvtomeme",
		imagePath: baseImagePath + "x.svg",
		altText: projectName + "X (Twitter)",
		color: "#000000"
	},
	{
		link: undefined,
		imagePath: baseImagePath + "medium.svg",
		altText: projectName + "Medium",
		color: "#000000"
	},
	{
		link: undefined,
		imagePath: baseImagePath + "coingecko.svg",
		altText: projectName + "Coingecko",
		color: "#8bc53f"
	},
	{
		link: undefined,
		imagePath: baseImagePath + "github.svg",
		altText: projectName + "Github",
		color: "#000000"
	},
	{
		link: "https://luvtomeme.gitbook.io/luv-to-meme",
		imagePath: baseImagePath + "gitbook.svg",
		altText: projectName + "Gitbook",
		color: "#000000"
	}
];

export function getSocials() {
	return socials.filter(social => social.link !== undefined);
}