import { createContext, useState } from "react";

export const ProviderModalContext = createContext();

export function ProviderModalProvider({children}) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<ProviderModalContext.Provider value={{isModalOpen, setIsModalOpen}}>
			{children}
		</ProviderModalContext.Provider>
	);
}
