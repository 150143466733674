import { Decimal } from "decimal.js";

import { currentAccount } from "./web3Base.js";
import { getBottomValue, getTokenFee, swapBuy, swapSell } from "./web3History.js";
import { postChatMessage } from "../api/backend.js";
import { toDateString } from "../helper.js";

// ---- GETTER (CHAT) ----

export async function getChartData(token) {
	return [
		{ time: '2024-09-22', open: 75.16, high: 82.84, low: 36.16, close: 45.72 },
		{ time: '2024-09-23', open: 45.12, high: 53.90, low: 45.12, close: 48.09 },
		{ time: '2024-09-24', open: 60.71, high: 60.71, low: 53.39, close: 59.29 },
		{ time: '2024-09-25', open: 68.26, high: 68.26, low: 59.04, close: 60.50 },
		{ time: '2024-09-26', open: 67.71, high: 105.85, low: 66.67, close: 91.04 },
		{ time: '2024-09-27', open: 91.04, high: 121.40, low: 82.70, close: 111.40 },
		{ time: '2024-09-28', open: 111.51, high: 142.83, low: 103.34, close: 131.25 },
		{ time: '2024-09-29', open: 131.33, high: 151.17, low: 77.68, close: 96.43 },
		{ time: '2024-09-30', open: 106.33, high: 110.20, low: 90.39, close: 98.10 },
		{ time: '2024-09-31', open: 109.87, high: 114.69, low: 85.66, close: 111.26 },
	];
}

export async function getChatMessages(token) {
	return [
		{
			author: "0x1bBf43506A0C2569e4B509D1a8AEa932dbC6260a",
			date: "2024-09-24T16:00:00",
			text: "hello"
		},
		{
			author: "0x1bBf43506A0C2569e4B509D1a8AEa932dbC6260a",
			date: "2024-09-24T16:00:00",
			text: "nice coin, let's go"
		},
		{
			author: "0x1bBf43506A0C2569e4B509D1a8AEa932dbC6260a",
			date: "2024-09-24T16:00:00",
			text: "all in, 10000000x"
		},
	];
}

// ---- INTERACTIONS (SWAP) ----

export async function buy(token, buyAmount, slippage) {
	let fee = await getTokenFee(token);

	let one = new Decimal("1");
	let hundred = new Decimal("100");
	let slippageDecimal = new Decimal(slippage);

	let slippagePercentage = slippageDecimal.dividedBy(hundred);
	let slippageAmount = one.minus(slippagePercentage);

	let outAmount = await getBottomValue(token, true, buyAmount, fee);
	let outAmountSlippage = outAmount.times(slippageAmount).toDecimalPlaces(18);

	return swapBuy(token, buyAmount, outAmountSlippage);
}

export async function sell(token, sellAmount, slippage) {
	let fee = await getTokenFee(token);

	let one = new Decimal("1");
	let hundred = new Decimal("100");
	let slippageDecimal = new Decimal(slippage);
	
	let slippagePercentage = slippageDecimal.dividedBy(hundred);
	let slippageAmount = one.minus(slippagePercentage);

	let outAmount = await getBottomValue(token, false, sellAmount, fee);
	let outAmountSlippage = outAmount.times(slippageAmount).toDecimalPlaces(18);

	return swapSell(token, sellAmount, outAmountSlippage);
}

// ---- INTERACTIONS (CHAT) ----

export async function sendChatMessage(token, message) {
	return Promise.resolve();

	let currentDate = new Date();
	let currentDateString = toDateString(currentDate);

	let date = currentDateString.split(", ")[0];
	let time = currentDateString.split(", ")[1];

	let finalDate = date.split("/").reverse().join("-");

	let finalDateString = finalDate + "T" + time;

	const newMessage = {
		author: currentAccount,
		date: finalDateString,
		text: message
	};

	return postChatMessage(currentAccount, message);
}
