import { getChain } from "./chains.js";
import { isTestnetPath } from "../lib/helper.js";

const basePath = "/images/tokens/";

const tokens = {
	BSCT: [
		{
			symbol: "BNB",
			wrappedContract: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
			decimals: 18,
			coingeckoId: "binance-usd",
			imagePath: basePath + "BUSD.svg"
		},
		{
			symbol: "USDC",
			contract: "0x64544969ed7EBf5f083679233325356EbE738930",
			decimals: 18,
			coingeckoId: "binance-usd",
			imagePath: basePath + "BUSD.svg"
		},
		{
			symbol: "LUV",
			contract: "0x30057774dF1EB6Ee171f7fFcAD8e71d28C23945A",
			decimals: 18,
			coingeckoId: "binance-usd",
			imagePath: basePath + "BUSD.svg"
		},
		{
			symbol: "freeLUV",
			contract: "0x0998bC2eed5E8009a0B12A3A285a3dDfCbD0f04d",
			decimals: 18,
			coingeckoId: "binance-usd",
			imagePath: basePath + "BUSD.svg"
		},
	]
};

// ---- GET ALL TOKENS ----

export function getAllTokens() {
	let allTokens = [];
	let currentTokens = tokens;

	if (!isTestnetPath()) {
		delete currentTokens.BSCT;
	}

	for (const [chainName, tokens] of Object.entries(currentTokens)) {
		let chain = getChain(chainName);

		let newTokens = tokens.map(token => {
			return {...token, chain: chain};
		});

		allTokens = [...allTokens, ...Object.values(newTokens)];
	}

	return allTokens;
}

// ---- GET SPECIFIC TOKEN ----

export function getToken(chain, symbol) {
	return getAllTokens().find(token => token.chain.nameId === chain && token.symbol === symbol);
}

export function getTokenByContract(chain, contract) {
	return getAllTokens().find(token => token.chain.nameId === chain && token.contract === contract);
}
