import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Main from "./components/structure/Main.js";
import LoadingSpinnerPage from "./components/common/loading/LoadingSpinnerPage.js";
import { BalancesProvider } from "./components-helper/contexts/BalancesProvider.js";
import { ConnectionProvider } from "./components-helper/contexts/ConnectionProvider.js";
import { CreatedTokensProvider } from "./components-helper/contexts/CreatedTokensProvider.js";
import { ProviderModalProvider } from "./components-helper/contexts/ProviderModalProvider.js";
import { WindowWidthProvider } from "./components-helper/contexts/WindowWidthProvider.js";

// ---- LAZY CONTAINERS ----

const LandingContainer = lazy(() => import("./components/content/landing/LandingContainer.js"));

const CreateContainer = lazy(() => import("./components/content/create/CreateContainer.js"));
const ManageContainer = lazy(() => import("./components/content/manage/ManageContainer.js"));
const DaoContainer = lazy(() => import("./components/content/dao/DaoContainer.js"));

const StakingContainer = lazy(() => import("./components/content/staking/StakingContainer.js"));
const HistoryContainer = lazy(() => import("./components/content/history/HistoryContainer.js"));

const WhitelistContainer = lazy(() => import("./components/content/whitelist/WhitelistContainer.js"));
const WhitelistOwnerContainer = lazy(() => import("./components/content/whitelistOwner/WhitelistOwnerContainer.js"));
const RaiseContainer = lazy(() => import("./components/content/raise/RaiseContainer.js"));

const TokenContainer = lazy(() => import("./components/content/token/TokenContainer.js"));

const TokenomicsContainer = lazy(() => import("./components/content/tokenomics/TokenomicsContainer.js"));
const AboutContainer = lazy(() => import("./components/content/about/AboutContainer.js"));

const NotFoundContainer = lazy(() => import("./components/content/notFound/NotFoundContainer.js"));

// ---- APP ----

export default function App() {
	return (
		<BrowserRouter>
			<ConnectionProvider>
				<BalancesProvider>
					<CreatedTokensProvider>
						<ProviderModalProvider>
							<WindowWidthProvider>
								<Suspense fallback={<LoadingSpinnerPage/>}>
									<Routes>
										<Route path="/" element={<LandingContainer/>}/>

										{/* <Route path="/" element={<Main/>}>
											<Route exact path="/create" element={<CreateContainer/>}/>
											<Route exact path="/manage" element={<ManageContainer/>}/>
											<Route exact path="/dao" element={<DaoContainer/>}/>

											<Route exact path="/staking" element={<StakingContainer/>}/>
											<Route exact path="/history" element={<HistoryContainer/>}/>

											<Route exact path="/whitelist" element={<WhitelistContainer/>}/>
											<Route exact path="/whitelist/owner" element={<WhitelistOwnerContainer/>}/>
											<Route exact path="/raise" element={<RaiseContainer/>}/>

											<Route exact path="/token/:chain/:contract" element={<TokenContainer/>}/>

											<Route exact path="/tokenomics" element={<TokenomicsContainer/>}/>
											<Route exact path="/about" element={<AboutContainer/>}/>
										</Route>

										<Route path="*" element={<NotFoundContainer/>}/> */}
									</Routes>
								</Suspense>
							</WindowWidthProvider>
						</ProviderModalProvider>
					</CreatedTokensProvider>
				</BalancesProvider>
			</ConnectionProvider>
		</BrowserRouter>
	);
}