import { useContext } from "react";
import styles from "./Sidebar.module.scss";
import { IoRocketSharp } from "react-icons/io5";
import { FaCircle } from "react-icons/fa6";
import { MdBarChart } from "react-icons/md";
import { FaPiggyBank } from "react-icons/fa6";
import { MdPeopleAlt } from "react-icons/md";
import { TbChecklist } from "react-icons/tb";
import { GiLockedChest } from "react-icons/gi";
import { IoIosPie } from "react-icons/io";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { IoMdArrowDropleft } from "react-icons/io";
import ExternalLink from "../common/links/ExternalLink.js";
import InternalLink from "../common/links/InternalLink.js";
import Logo from "../common/others/Logo.js";
import { CreatedTokensContext } from "../../components-helper/contexts/CreatedTokensProvider.js";

import { projectName } from "../../data/project.js";
import { getSocials } from "../../data/socials.js";

export default function Sidebar() {
	const socials = getSocials();

	const { createdTokens } = useContext(CreatedTokensContext);

	return (
		<div className={styles.container}>
			<nav className={styles.upper_container}>
				<div className={styles.logo_container}>
					<Logo/>
				</div>

				<div className={styles.link_group_container}>
					<div className={styles.link_group}>
						<div className={styles.link_group_title}>TOKENS</div>

						<div className={styles.link_container}>
							<InternalLink link={"/history"} otherClasses={styles.link} activeClasses={styles.link_active}>
								<div className={styles.link_title_container}>
									<IoRocketSharp className={styles.link_logo}/>
									<span>Launches</span>
								</div>
								<IoMdArrowDropleft/>
							</InternalLink>

							<InternalLink link={"/create"} otherClasses={styles.link} activeClasses={styles.link_active}>
								<div className={styles.link_title_container}>
									<FaCircle className={styles.link_logo}/>
									<span>Create</span>
								</div>
								<IoMdArrowDropleft/>
							</InternalLink>

							{
								(createdTokens !== undefined && createdTokens.length > 0) &&
								<InternalLink link={"/manage"} otherClasses={styles.link} activeClasses={styles.link_active}>
									<div className={styles.link_title_container}>
										<MdBarChart className={styles.link_logo}/>
										<span>Manage</span>
									</div>
									<IoMdArrowDropleft/>
								</InternalLink>
							}
						</div>
					</div>

					<div className={styles.link_group}>
						<div className={styles.link_group_title}>EXPLORE</div>

						<div className={styles.link_container}>
							<InternalLink link={"/staking"} otherClasses={styles.link} activeClasses={styles.link_active}>
								<div className={styles.link_title_container}>
									<FaPiggyBank className={styles.link_logo}/>
									<span>Staking</span>
								</div>
								<IoMdArrowDropleft/>
							</InternalLink>

							<InternalLink link={"/dao"} otherClasses={styles.link} activeClasses={styles.link_active}>
								<div className={styles.link_title_container}>
									<MdPeopleAlt className={styles.link_logo}/>
									<span>DAO</span>
								</div>
								<IoMdArrowDropleft/>
							</InternalLink>

							<InternalLink link={"/whitelist"} otherClasses={styles.link} activeClasses={styles.link_active}>
								<div className={styles.link_title_container}>
									<TbChecklist className={styles.link_logo}/>
									<span>Whitelist</span>
								</div>
								<IoMdArrowDropleft/>
							</InternalLink>

							<InternalLink link={"/raise"} otherClasses={styles.link} activeClasses={styles.link_active}>
								<div className={styles.link_title_container}>
									<GiLockedChest className={styles.link_logo}/>
									<span>Raise</span>
								</div>
								<IoMdArrowDropleft/>
							</InternalLink>
						</div>
					</div>

					<div className={styles.link_group}>
						<div className={styles.link_group_title}>MORE</div>

						<div className={styles.link_container}>
							<InternalLink link={"/tokenomics"} otherClasses={styles.link} activeClasses={styles.link_active}>
								<div className={styles.link_title_container}>
									<IoIosPie className={styles.link_logo}/>
									<span>Tokenomics</span>
								</div>
								<IoMdArrowDropleft/>
							</InternalLink>
							
							<InternalLink link={"/about"} otherClasses={styles.link} activeClasses={styles.link_active}>
								<div className={styles.link_title_container}>
									<IoChatbubbleEllipses className={styles.link_logo}/>
									<span>About</span>
								</div>
								<IoMdArrowDropleft/>
							</InternalLink>
						</div>
					</div>
				</div>
			</nav>

			<div className={styles.sidebar_footer}>
				<img className={styles.sidebar_footer_image} src={"/images/decorations/biker_white_outline.png"} alt={projectName + " - Biker Logo"}/>

				<ExternalLink link={"https://google.com"} isButton={true} buttonClass={"button_green_small"}>Get LUV</ExternalLink>

				<div className={styles.social_container}>
					{
						socials.map((socialData, index) => {
							return (
								<div key={index}>
									<ExternalLink link={socialData.link}>
										<div className={styles.social_icon_container}>
											<img className={styles.social_icon} src={socialData.imagePath} alt={socialData.altText}/>
										</div>
									</ExternalLink>
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
}