import { useState, useEffect, useContext } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import styles from "./SearchBar.module.scss";
import SearchBarToken from "./SearchBarToken.js";
import StringInput from "../../common/form/StringInput.js";
import { CreatedTokensContext } from "../../../components-helper/contexts/CreatedTokensProvider.js";

export default function SearchBar() {
	const [searchText, setSearchText] = useState("");
	const [filteredTokens, setFilteredTokens] = useState([]);

	const { createdTokens } = useContext(CreatedTokensContext);

	// ---- HOOKS ----

	useEffect(() => {
		filterTokens();
	}, [createdTokens, searchText]);

	// ---- FUNCTIONS ----

	function filterTokens() {
		if (createdTokens !== undefined && searchText !== "") {
			let filteredTokens = createdTokens.filter(token => {
				let filters = [token.basicData.name, token.basicData.symbol, token.contract];

				return filters.some(field => field.toLowerCase().includes(searchText.toLowerCase()));
			});

			let searchBarTokens = filteredTokens.map((token, index) => {
				return <SearchBarToken key={index} token={token}/>;
			});

			setFilteredTokens(searchBarTokens);
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.input_container}>
				<FaMagnifyingGlass/>

				<StringInput
					getter={searchText}
					setter={setSearchText}
					placeholder={"Search..."}
					isRequired={false}
					isUnconnectedLoading={createdTokens === undefined}
					isUnconnectedUsable={true}
					maxLength={42}/>
			</div>

			{
				(searchText !== "") &&
				<div className={styles.result_container}>
					{
						filteredTokens.length > 0
						?
							filteredTokens
						:
							<span>No tokens found.</span>
					}
				</div>
			}
		</div>
	);
}