import { createContext, useState, useEffect } from "react";
import { getCreatedTokens } from "../../lib/web3/web3BaseAdd.js";

export const CreatedTokensContext = createContext();

export function CreatedTokensProvider({children}) {
	const [createdTokens, setCreatedTokens] = useState(undefined);

	// ---- HOOKS ----

	useEffect(() => {
		updateTokens();
	}, []);

	// ---- FUNCTIONS ----

	function updateTokens() {
		getCreatedTokens().then(result => {
			setCreatedTokens(result);
		});
	}

	return (
		<CreatedTokensContext.Provider value={{ createdTokens, updateTokens }}>
			{children}
		</CreatedTokensContext.Provider>
	);
}