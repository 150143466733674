import styles from "./SearchBarToken.module.scss";
import InternalLink from "../../common/links/InternalLink.js";

export default function SearchBarToken({token}) {
	let link = "/token/" + token.chain.nameId + "/" + token.contract;

	return (
		<InternalLink link={link} otherClasses={styles.container}>
			<span>{token.basicData.symbol}</span>
			<span className={styles.contract}>({token.contract})</span>
		</InternalLink>
	);
}